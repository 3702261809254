import { createRoot } from "react-dom/client";

import { LazyLoadComponent } from "./widgets/lazy_loader";
import { Logo64 } from "./base/icons";

import "./index.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <LazyLoadComponent
    loader={
      <div className="center">
        <Logo64 />
      </div>
    }
    component={() => import("./app.jsx")}
    componentName="App"
  />
);
